// Mobile burger-menu func.
let _burgerButton = document.querySelector('.burger__button')
let _burgerMenu = document.querySelector('.nav__list_mobile')
let _navLinks = document.querySelectorAll('.nav__link_anchor, .get-touch__button')
let contactUsLink = document.querySelector('.get-touch__button')

if (_burgerButton) {
    _burgerButton.addEventListener('click', evt => {
        _burgerButton.classList.toggle('active')
        _burgerMenu.classList.toggle('active')
        if (!contactUsLink.classList.contains('get-touch__button_white-cross')) {
            contactUsLink.classList.add('get-touch__button_white-cross')
        } else {
            contactUsLink.classList.remove('get-touch__button_white-cross')
            contactUsLink.classList.add('get-touch__button_black-cross')
        }
        document.documentElement.classList.toggle('overflow-hidden')
    })
}

_navLinks.forEach(link => {
    link.addEventListener('click', evt => {
        _burgerButton.classList.remove('active')
        _burgerMenu.classList.remove('active')
        if (!window.location.href.includes('vacancies.json')) {
            contactUsLink.classList.remove('get-touch__button_white-cross')
        }
        // document.documentElement.style.overflowY = 'auto'
    })
})